import api from './index'
import authHeader from './auth-header'

class serviceUser {
    getAll() 
    {
        return api.get('user/all', {headers: authHeader()})
    }
    getById(id) {
        return api.get('user/'+ id, {headers: authHeader()})
    }
    getUserByJwt(jwt) {
        return api.get('user/jwt/'+ jwt, {headers: authHeader()})
    }
    getSecretCode() {
        return api.get('code/generate', {headers: authHeader()})
    }
    
    getSecretKey() {
        return api.get('user/secret', {headers: authHeader()})
    }

    
    isSecretEnabled() {
        return api.post('user/checkSecret',null, {headers: authHeader()})
    }
    
    is2FaEnabled() {
        return api.post('user/check2FaEnabled',null, {headers: authHeader()})
    }
    
    saveSecretCode(formData) {
        return api.put('code/save', formData, {headers: authHeader()})
    }

    enable2FA(formData) {
        return api.put('code/enable2FA', formData, {headers: authHeader()})
    }
    
    
    changePassword(formData) {
        return api.post('user/changePassword', formData, {headers: authHeader()})
    }

    insert(formData) {
        return api.post('user/create', formData, {headers: authHeader()})    
    }

    update(formData) {
        return api.put('user/update', formData, {headers: authHeader()})   
    }
    remove(id) {
        return api.post('user/delete/'+id, null, {headers: authHeader()})  
    }
}

export default new serviceUser()